.steps-wrapper {
	height: 100%;
}

.steps-body {
	height: calc(100vh - 200px) !important;
	border: none;
}

.steps-body.footer {
	height: calc(100vh - 142px) !important;
}

#awesome-pdf-viewer > .main {
	height: calc(90vh - 155px) !important;
}

.review-main-container {
	display: flex;
}

.review-left-panel {
	overflow: auto;
	background-color: white;
}

.summary-step-container {
	height: 100%;
	min-height: 500px;
}

.content-left {
	min-width: 260px;
	padding-top: 20px;
	height: 98%;
}

.content-right {
	margin-left: 25px;
	padding-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
	height: 95%;
}

.payment-refund-container {
	height: 50%;
	overflow: auto;
}

.step-layout {
	height: 100%;
}

.step-layout-header {
	padding: 5px;
	font-size: 30px;
	height: 50px;
	background-color: white;
}

.step-layout-left-panel-header,
.step-layout-body-header {
	font-size: 20px;
	height: 50px;
	padding: 10px 15px 0px 15px;
	font-weight: bolder;
}

.step-layout-left-panel-body,
.step-layout-body {
	height: calc(100% - 50px);
	overflow: auto;
	padding: 0px 15px 0px 15px;
}

.step-layout-content {
	display: flex;
	min-height: 450px;
	height: 100%;
}

.step-layout-header + .step-layout-content {
	height: calc(100% - 50px);
}

.step-layout-body-container {
	margin: 0px auto 0px auto;
}

.step-layout-left-panel,
.step-layout-body-container {
	border: 1px solid #eeeeee;
	border-radius: 2px;
	background-color: white;
}

.custom-button {
	background-color: #5ffc5f1c;
	border: 2px solid #83c786;
	height: 65px;
	border-radius: 5px;
	width: 225px;
	display: flex;
	padding: 8px;
	cursor: pointer;
}

.custom-button:hover {
	background-color: #9fff9f6b;
}

.custom-button-icon-container {
	width: 25%;
}

.custom-button-text-container {
	width: 75%;
	overflow: hidden;
	user-select: none;
	color: #0274bb;
	font-weight: bolder;
}

.upload-documents-container {
	height: calc(100% - 25px);
}

.upload-documents-area {
	height: calc(91% - 100px);
}

.download-documents-area {
	height: 100px;
}

.upload-documents-title {
	margin: 20px 0px 25px 0px;
}

.text-blue {
	color: #0274bb;
}

.company-info > .company-address {
	height: 65%;
	min-height: 100px;
}

.download-documents-area > .downlod-button-container {
	margin: 10px 0px 0px 0px;
}

.company-info > .company-fax {
	height: 35%;
	min-height: 100px;
}

#signed-document-upload {
	height: calc(100% - 35px);
}

#signed-document-upload.with-border {
	border: 3px dashed #9ed4a0;
}

.upload-area {
	height: 100px;
	padding: 10% 0px 0px 0px;
	text-align: center;
}

.upload-area.minimized {
	border: 3px dashed #9ed4a0;
	margin-bottom: 20px;
	padding: 4% 0px 0px 0px !important;
}

.uploaded-files-list {
	height: calc(100% - 120px);
	overflow: auto;
	padding: 10px;
}

.uploaded-files-list.minimized {
	border: 2px solid #9ed4a0;
}

.file-list-container {
	display: inline;
	height: 100%;
}

.file-list-container > p {
	margin-bottom: 2rem;
}

.file-list-item {
	border-top: 1px solid #cbd2d9;
	display: flex;
	justify-content: space-between;
	padding: 1.5rem 2rem;
}

.file-list-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.file-list-item .icon {
	font-size: 2.4rem;
	margin-right: 1rem;
}

ul.task-items {
	padding: 0px !important;
}

ul.task-items li.item {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	margin: 2px 0;
	padding: 5px;
	background: #fff;
	box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}

ul.task-items li.item.type1 .task .icon {
	cursor: pointer;
}

ul.task-items li.item .task {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	width: 240px;
}

ul.task-items li.item .task .icon {
	width: 33px;
	height: 31px;
	border-radius: 20px;
	border-color: grey;
	border: 0px solid grey;
	padding: 5px 0px 0px 9px;
}

ul.task-items li.item .task .name {
	margin-left: 20px;
	width: 100%;
	height: 25px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

ul.task-items li.item .dates {
	text-align: center;
}

ul.task-items li.item .dates .bar,
ul.task-items li.item .priority .bar {
	height: 25px;
	border-radius: 15px;
}

ul.task-items li.item .priority {
	text-align: center;
	cursor: pointer;
}

.drop-area-highlight {
	background: #fff;
	box-shadow: 0 0 5px 10px rgba(149, 255, 26, 0.22);
}

.btn-ssr {
	background-color: white;
	color: #017cff;
	border-color: #28a745;
	border-radius: 20px;
	height: 35px;
	border: 1px solid #28a745;
	font-weight: bold;
	padding: 0px 15px 0px 15px;
}

.step-icon {
	height: 100%;
	width: 65%;
}

.k1-content {
	border: 1px #ccc solid;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	box-shadow: 0px 0px 5px #cccccc;
	-webkit-box-shadow: 0px 0px 5px #cccccc;
	-moz-box-shadow: 0px 0px 5px #cccccc;
	padding: 15px;
	margin-top: 10px;
	text-align: center;
}

.border-box {
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 10px;
}

.marginAuto {
	margin: auto;
}

.k1-title {
	font-size: 2rem;
	text-align: center;
}

.padding05 {
	padding: 5px;
}

.overflow-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.modal475 .modal-dialog {
	width: 475px;
	max-width: 475px;
	font-family: 'Mulish';
}

.modal750 .modal-dialog {
	width: 90%;
	max-width: 750px;
	font-family: 'Mulish';
}

.suiteModal .modal-dialog {
	display: flex;
	margin: 1.75rem auto;
}

.suiteModal .modal-dialog .modal-content {
	border-radius: 4.8px;
	box-sizing: border-box;
	border: none;
	background-color: transparent;
}

.suiteModal .modal-dialog .modal-content .lighttheme {
	background: white;
}

.suiteModal .modal-dialog .modal-content .lighttheme .modal-title {
	color: #212529;
	font-weight: 700;
}

.suiteModal .modal-dialog .modal-content .lighttheme .closeIcon {
	color: rgba(0, 0, 0, 0.5);
}

.suiteModal .modal-dialog .modal-content .darktheme {
	background: #05386b;
}

.suiteModal .modal-dialog .modal-content .darktheme .modal-title {
	color: white;
	font-weight: 500;
}

.suiteModal .modal-dialog .modal-content .darktheme .closeIcon {
	color: #99bde1;
}

.suiteModal .modal-dialog .modal-content .modal-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #dee2e6;
	line-height: 24px;
	font-size: 20px;
	padding: 9px 1rem;
	border-top-left-radius: 4.8px;
	border-top-right-radius: 4.8px;
}

.suiteModal .modal-dialog .modal-content .modal-header .closeIcon {
	width: 26px;
	line-height: 24px;
	cursor: pointer;
}

.suiteModal .modal-dialog .modal-content .modal-header .closeIcon svg {
	width: 26px;
	height: 26px;
}

.suiteModal .modal-dialog .modal-content .modal-header .modal-title {
	line-height: 24px;
	font-size: 20px;
}

.k1ConsentPopUp .modal-body {
	background-color: #fff;
	padding: 1.5rem 20px 2rem;
}

.k1ConsentPopUp .modal-body .innerHeader {
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	color: #000000;
}

.k1ConsentPopUp .modal-body .agreeHeader {
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	color: #669440;
	margin: 1.5rem 0 0.7rem 0;
}

.k1ConsentPopUp .modal-body .bulletPointsAgree {
	margin-left: -1rem;
	margin-bottom: 2rem;
}

.k1ConsentPopUp .modal-body .bulletPointsAgree li {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	color: #000000;
}

.k1ConsentPopUp .modal-body .disagreeHeader {
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	color: #cc4a43;
	margin: 1.5rem 0 0.7rem 0;
}

.k1ConsentPopUp .modal-body .bulletPointsDisagree {
	margin-left: -1rem;
}

.k1ConsentPopUp .modal-body .bulletPointsDisagree li {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	color: #000000;
}

.k1ConsentPopUp .modal-footer {
	background-color: #fff;
}

.k1ConsentPopUp .modal-footer .k1ConsentDeclineButton {
	width: 125px;
	min-width: 0px !important;
	height: 38px;
	padding: 6px 12px;
	border-radius: 2px;
	border: 1px solid #cc4a43 !important;
	background: #cc4a43 !important;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	color: #ffffff;
}

.k1ConsentPopUp .modal-footer .k1ConsentAcceptButton {
	width: 125px;
	min-width: 0px !important;
	height: 38px;
	padding: 6px 12px;
	border-radius: 2px;
	border: 1px solid #669440 !important;
	background: #669440 !important;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	color: #ffffff;
}

.downloadPageContainer {
	display: flex;
	justify-content: center;
}

.downloadPageInnerContainer {
	width: 80%;
	max-width: 1013px;
}

.downloadSectionHeader {
	margin-top: 2rem;
	font-family: Mulish;
	font-size: 18px;
	font-weight: 700;
	line-height: 25px;
	letter-spacing: 0px;
	color: #212529;
}

.downloadSectionText {
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	color: #212529;
}

.downloadDocumentsContainer {
	margin-top: 2rem;
}

.packageBlock {
	display: flex;
	align-items: center;
}

.packageBlockText {
	margin: 1px 10px 0px 10px;
	text-decoration: underline;
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	color: #212529;
}

.packageBlock .downloadIcon {
	cursor: pointer;
}

.pdfBlock {
	margin-top: 8px;
	display: flex;
	align-items: center;
}

.pdfBlockText {
	margin: 1px 10px 0px 10px;
	text-decoration: underline;
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	color: #212529;
}

.pdfBlock .downloadIcon {
	cursor: pointer;
}

.disclosuresContainer {
	margin-top: 2rem;
}

.disclosuresContainer ol {
	padding-left: 24px !important;
}

.disclosuresContainer ol li {
	font-family: Mulish;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	color: #212529;
}

.disclosuresHeaderText {
	font-family: Mulish;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	color: #212529;
}

.disclosuresSemiBoldText {
	font-family: Mulish;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	color: #212529;
}

.disclosuresBoldText {
	font-family: Mulish;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	color: #212529;
}

#accountPopover {
	width: 240px;
	border-radius: 4px;
	inset: 6px auto auto 0 !important;
	margin-left: -0.5rem !important;
	z-index: 1;
}

#accountPopover .arrow {
	display: none;
}

#accountPopover .account-popover-body {
	font-family: 'Mulish';
	font-size: 16px;
	line-height: 1.5;
	padding: 8px 0;
	color: #212529;
}

#accountPopover .account-popover-body .profile-name {
	padding: 8px 16px;
	color: #0973ba;
	font-weight: 700;
	line-height: 1.2;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#accountPopover .account-popover-body .my-account {
	padding: 4px 16px;
	color: #151617;
	font-weight: 400;
	cursor: pointer;
}

#accountPopover .account-popover-body .dropdown-item {
	&:hover {
		background-color: #c4c6c8;
	}

	&:active {
		background-color: #05386b;
		color: #fff;
	}
}

#accountPopover .account-popover-body .dropdown-divider {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin: 8px 0;
	opacity: 1;
	overflow: hidden;
	box-shadow: 0 -0.5px 0 0 rgba(0, 0, 0, 0.15);
}

#accountPopover .account-popover-body .log-out {
	padding: 4px 16px;
	color: #151617;
	font-weight: 700;
	cursor: pointer;
}

.k1MyAccountPopUp .modal-body {
	background-color: #fff;
	padding: 0.8rem 22px 2rem !important;
}

.k1MyAccountPopUp .modal-body .k1InformationHeader {
	font-family: Mulish;
	font-size: 15px;
	font-weight: 700;
	line-height: 15px;
	letter-spacing: 0px;
	color: #212529;
}

.k1MyAccountPopUp .modal-body .form-label {
	font-family: Mulish;
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	letter-spacing: 0em;
	color: #212529;
	margin-bottom: 2px !important;
	margin-top: 0.5rem !important;
}

#txtk1Name {
	width: 430px;
	height: 31px;
	padding: 4px 8px;
	border-radius: 2px;
	border: 1px solid #a6a9ac;
	background: #f0f1f1;
	color: #a6a9ac;
	font-family: Mulish;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
}

#txtk1SsnEin {
	width: 200px;
	height: 31px;
	padding: 4px 12px 4px 8px;
	border-radius: 2px;
	border: 1px solid #a6a9ac;
	background: #f0f1f1;
	color: #a6a9ac;
	font-family: Mulish;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
}

.k1PartnerTypeColumn {
	padding-left: 4px;
}

#drpEntityType .k1AccountPartnerType__control {
	width: 210px;
	height: 31px;
	min-height: 31px;
	padding: 0px 0px 0px 8px;
	border-radius: 2px;
	border: 1px solid #898d91;
	color: #212529;
	font-family: Mulish;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
	box-shadow: none;
}

#drpEntityType .k1AccountPartnerType__value-container {
	padding: 0px;
}

#drpEntityType .k1AccountPartnerType__indicator-separator {
	width: 0px;
}

#drpEntityType .k1AccountPartnerType__indicator {
	padding: 0px 8px 0px 0px;
}

#drpEntityType .k1AccountPartnerType__menu {
	margin-top: 0px !important;
}

#txtK1Address {
	width: 430px;
	height: 83px;
	border-radius: 2px;
	border: 1px solid #898d91;
	padding: 4px 8px;
	font-family: Mulish;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
	color: #212529;
	resize: none;
	outline: none;
	box-shadow: none;
}

.groupFormElementAddress {
	margin-bottom: 0px !important;
}

#txtK1Email {
	width: 430px;
	height: 31px;
	border-radius: 2px;
	border: 1px solid #898d91;
	padding: 4px 8px;
	font-family: Mulish;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
	color: #212529;
	outline: none;
	box-shadow: none;
}

.groupFormElementEmailK1 {
	margin-bottom: 0.5rem !important;
}

.mobileNumberSectionK1Account label {
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	color: #212529;
	margin-bottom: 2px;
}

.mobileNumberSectionK1Account .countryCodeMobileNumberWrapper {
	position: relative;
	height: 31px;
	width: 200px;
	border: 1px solid #898d91;
	border-radius: 2px;
}

.mobileNumberSectionK1Account .countryCodeMobileNumberWrapperDisabled {
	border: 1px solid #a6a9ac !important;
}

.mobileNumberSectionK1Account .countryCodeMobileNumberWrapper .phoneInput {
	margin-bottom: 3px;
}

.mobileNumberSectionK1Account .countryCodeMobileNumberWrapper .phoneInput .special-label {
	display: none;
}

.mobileNumberSectionK1Account .countryCodeMobileNumberWrapper .phoneInput input {
	visibility: hidden;
	height: 31px;
	display: none;
}

.mobileNumberSectionK1Account .countryCodeMobileNumberWrapper .phoneInput .flag-dropdown {
	height: 29px;
	width: 55px;
	border: 0px solid #898d91;
	border-right: 1px solid #898d91;
	background-color: #f0f1f1;
}

.mobileNumberSectionK1Account .countryCodeMobileNumberWrapper .phoneInput .flag-dropdown .country-list {
	font-family: Mulish, sans-serif;
	font-size: 14px;
	width: 200px;
	margin-top: 3px;
	max-height: 107px;
}

.mobileNumberSectionK1Account .countryCodeMobileNumberWrapper .phoneInput .flag-dropdown .country-list li {
	padding: 6px 0 6px 7px;
}

.mobileNumberSectionK1Account .countryCodeMobileNumberWrapper .phoneInput .flag-dropdown .selected-flag {
	padding-left: 11px;
	width: 54px;
}

.mobileNumberSectionK1Account .countryCodeMobileNumberWrapper .phoneInput .flag-dropdown .arrow {
	border: solid #212529;
	border-width: 0 1px 1px 0;
	height: 6px;
	margin-left: 6px;
	margin-top: 1px;
	top: 0;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	width: 6px;
}

.mobileNumberSectionK1Account .countryCodeMobileNumberWrapper .mobileNumberInput {
	position: absolute;
	font-family: sans-serif, 'Poppins';
	height: 29px;
	width: 143px;
	border: 0px solid #898d91;
	color: #212529;
	font-size: 14px;
	font-weight: 400;
	padding: 4px 8px;
	left: 55px;
	top: 0px;
	outline: none;
	box-shadow: none;
}

.mobileNumberSectionK1Account .countryCodeMobileNumberWrapper .mobileNumberInput:disabled {
	border: 0px solid #a6a9ac !important;
	background-color: #f0f1f1 !important;
	color: #a6a9ac !important;
}

.k1MyAccountPopUp .modal-footer {
	background-color: #fff;
}

.verifyLinkWrapperDiv {
	margin-top: 7px;
}

.verifyMobileNumberLink {
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	color: #0973ba;
	text-decoration: none;
}

.verifyMobileNumberLink:hover {
	text-decoration: none;
	color: #0973ba !important;
}

#txtK1AccessCode {
	width: 199px;
	height: 31px;
	border-radius: 2px;
	border: 1px solid #898d91;
	padding: 4px 8px;
	font-family: Mulish;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
	color: #212529;
	outline: none;
	box-shadow: none;
}

.groupFormElementAccessCodeK1 {
	margin-bottom: 0px !important;
	margin-top: -0.5rem !important;
}

.cancelMyAccountK1Button {
	width: 100px !important;
	min-width: 0;
	height: 38px !important;
	padding: 6px 12px 6px 12px;
	border-radius: 2px;
	border: 1px solid #898d91 !important;
	background-color: #f0f1f1 !important;
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	color: #202223 !important;
}

.cancelMyAccountK1Button:hover {
	width: 100px !important;
	min-width: 0;
	height: 38px !important;
	padding: 6px 12px 6px 12px;
	border-radius: 2px;
	border: 1px solid #898d91 !important;
	background-color: #f0f1f1 !important;
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	color: #202223 !important;
}

.saveMyAccountK1Button {
	width: 100px !important;
	min-width: 0;
	height: 38px !important;
	padding: 6px 12px 6px 12px;
	border-radius: 2px;
	border: 1px solid #0973ba !important;
	background-color: #0973ba !important;
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	color: #ffffff;
}

.saveMyAccountK1Button:hover {
	width: 100px !important;
	min-width: 0;
	height: 38px !important;
	padding: 6px 12px 6px 12px;
	border-radius: 2px;
	border: 1px solid #0973ba !important;
	background-color: #0973ba !important;
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	color: #ffffff;
}

.disabledFormElement {
	border: 1px solid #a6a9ac !important;
	background-color: #f0f1f1 !important;
	color: #a6a9ac !important;
}

.invalidHeader {
	background-color: #0973ba;
	height: 55px;
	padding: 6px;
}

.invalidBodyContainer {
	height: calc(100vh - 55px - 30px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.invalidMessage {
	font-family: Mulish;
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0em;
	color: #212529;
	margin-top: 2rem;
}

@media (max-width: 400px) {
	.k1-title {
		font-size: 1rem;
	}
}

.upperLogoutContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 30px);
}
.logoutMessage1 {
	font-family: Mulish;
	font-size: 20px;
	font-weight: 700;
	line-height: 28px;
	letter-spacing: 0px;
	color: #212529;
	margin-top: 18px;
}
.logoutMessage2 {
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0px;
	color: #212529;
	margin-top: 8px;
}
.logoutFooterContainer {
	position: fixed;
	bottom: 0px;
	left: 0px;
	height: 30px;
	width: 100%;
	background-color: #f0f1f1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: Mulish;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;
	color: #212529;
	box-sizing: border-box;
	padding: 0 10px;
}
.logoutFooterLinks {
	text-decoration: none;
	color: #212529 !important;
}
.logoutFooterLinks:hover {
	text-decoration: none;
}
.sessionTimeoutContentWrapper {
	display: flex !important;
	flex-wrap: nowrap !important;
}
.sessionTimeoutSVGIconWrapper {
	width: auto !important;
}
.sessionTimeoutSVGIconWrapper svg {
	width: 100px;
	height: 100px;
}
.sessionTimeoutTextWrapper {
	width: auto !important;
}
