@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&display=swap');

html {
	height: 100%;
}

body {
	font-family: 'Mulish';
	font-size: 14px;
	line-height: 1.42857143;
	color: #333;
	height: 100%;
	overflow: auto;
}

#app {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/*min-height: 100vh;*/
	height: 100%;
	background-color: #fff;
}

@media (min-width: 320px) and (max-width: 480px) {
	.justify-content {
		display: flex;
		justify-content: end;
	}
}

.justify-content-center {
	justify-content: center;
}

.app-header {
	height: 55px;
	background-color: #0973ba;
	background-color: var(--headerBgColor);
	padding: 6px;
	z-index: 2;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.popover {
	border: 1px solid #0973ba;
}

.popover-header {
	background-color: #0973ba;
	color: white;
	font-size: 12px;
	font-weight: bold;
	margin: 1px;
}

.ddl-icon {
	margin-right: 10px;
	color: #0973ba;
	width: 15.75px;
}

.btn-primary {
	background-color: #88c656 !important;
	/*background-color: var(--bottonBgColor) !important;*/
	border-radius: 50px;
	min-width: 150px;
	font-weight: 500;
	font-size: 14px;
	border: 1px solid #88c656;
	/*border: 1px solid var(--bottonBorderColor);*/
	border-color: #88c656 !important;
	/*border-color: var(--bottonBorderColor) !important;*/
}

.btn-primary:hover {
	color: #fff;
	background-color: #88c656 !important;
	/*background-color: var(--bottonBorderColor) !important;*/
	border-color: #88c656 !important;
	/* border-color: var(--bottonBgColor) !important;*/
}

/*-------------Font Face -------------*/

@font-face {
	font-family: 'Journal';
	src: url('../fonts/journal.eot');
	src: url('../fonts/journal.eot?#iefix') format('embedded-opentype'), url('../fonts/journal.woff') format('woff'),
		url('../fonts/journal.ttf') format('truetype'), url('../fonts/journal.svg#JournalRegular') format('svg');
	font-weight: normal;
	font-style: normal;
}

.no-padding-left {
	padding-left: 0px !important;
}

.no-padding-right {
	padding-right: 0px !important;
}

.no-padding-top {
	padding-top: 0px !important;
}

.no-padding-bottom {
	padding-bottom: 0px !important;
}

.no-padding {
	padding: 0px 0px 0px 0px !important;
}

.no-margin {
	margin: 0 !important;
}

.width-100 {
	width: 100%;
}

.font-style-italic {
	font-style: italic;
}

.margin-left-50 {
	margin-left: 50px !important;
}

.margin-right-10 {
	margin-right: 10px !important;
}

.popover {
	border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

._loading_overlay_wrapper {
	min-height: 100vh;
}

._loading_overlay_overlay {
	margin-top: -52px;
}
/* width */
::-webkit-scrollbar {
	width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #d6cece;
	border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #777;
}

/* Initial application loading icon - START */
.loader,
.loader:before,
.loader:after {
	background: #ffffff;
	-webkit-animation: load1 1s infinite ease-in-out;
	animation: load1 1s infinite ease-in-out;
	width: 1em;
	height: 4em;
}

.loader {
	color: #ffffff;
	text-indent: -9999em;
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 11px;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

.loader:before,
.loader:after {
	position: absolute;
	top: 0;
	content: '';
}

.loader:before {
	left: -1.5em;
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.loader:after {
	left: 1.5em;
}

@-webkit-keyframes load1 {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 4em;
	}

	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
}

@keyframes load1 {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 4em;
	}

	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
}
/* Initial application loading icon - END */

/* Layout - Start */

header .company-name {
	color: var(--headerForeColor);
	font-size: 20px;
	margin-left: 0px;
}

.logo-img {
	height: 20px;
}

.content-wrapper {
	background-color: #fff;
	padding-top: 1px;
	padding-bottom: 2rem;
	min-height: calc(100vh - 55px - 30px);
}
.padding-top-eight {
	padding-top: 8px;
}

.header-left-container {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
	width: auto;
}

.header-right-container {
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
	min-width: 175px;
	position: absolute;
	right: 0;
	margin-top: 2px;
	display: flex;
	align-items: center;
	height: 55px;
}

.header-contact-info,
.header-logout,
.header-taxReturn {
	float: right;
	padding: 12px;
	color: var(--headerForeColor);
	cursor: pointer;
	margin-left: 15px;
}

.header-taxYear {
	padding: 12px 0px 12px 12px;
	color: var(--headerForeColor);
	cursor: default;
}

.header-account {
	padding: 12px;
	color: var(--headerForeColor);
	cursor: pointer;
}

.taxyearCalendarWrapper {
	display: flex;
	align-items: center;
	gap: 6px;
	font-family: Mulish;
	font-size: 16px;
	font-weight: 500;
	line-height: 26px;
	letter-spacing: 0px;
	color: inherit !important;
	text-decoration: none !important;
}

.taxyearCalendarWrapper:hover {
	color: inherit !important;
	text-decoration: none !important;
}

.clientAccountNameWrapper {
	display: flex;
	align-items: center;
	gap: 6px;
	font-family: Mulish;
	font-size: 16px;
	font-weight: 700;
	line-height: 26px;
	letter-spacing: 0px;
	color: inherit !important;
	text-decoration: none !important;
}

.clientAccountNameWrapper:hover {
	color: inherit !important;
	text-decoration: none !important;
}

.header-contact-info a {
	color: var(--headerForeColor);
}

.header-contact-info:hover,
.header-logout:hover {
	text-decoration: underline;
}

.mail {
	font-size: 14px;
	color: #88c656;
	font-weight: bold;
}

.mail a:hover {
	cursor: pointer;
}

.account-menu,
.taxYear-menu,
.taxReturn-menu {
	list-style: none;
	padding: 0 !important;
	margin: 0 !important;
}

.sidebarIconToggle,
#sidebarMenu {
	display: none;
}

#sidebarMenu {
	height: 100%;
	position: fixed;
	left: 0;
	width: 225px;
	margin-top: 30px;
	transform: translateX(-250px);
	transition: transform 250ms ease-in-out;
	/*background: linear-gradient(180deg, #FC466B 0%, #3F5EFB 100%);*/
	background-color: #0973ba;
	overflow: scroll;
	z-index: 999;
}

.sidebarMenuInner {
	margin: 0;
	padding: 0;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebarMenuInner li {
	list-style: none;
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	padding: 20px;
	cursor: pointer;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebarMenuInner li span {
	display: block;
	font-size: 14px;
	color: rgba(255, 255, 255, 0.5);
}

.sidebarMenuInner li a {
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	cursor: pointer;
	text-decoration: none;
}

input[type='checkbox']:checked ~ #sidebarMenu {
	transform: translateX(0);
}

#openSidebarMenu {
	transition: all 0.3s;
	box-sizing: border-box;
	display: none;
}

.sidebarIconToggle {
	transition: all 0.3s;
	box-sizing: border-box;
	cursor: pointer;
	position: absolute;
	z-index: 99;
	height: 100%;
	width: 100%;
	top: 10px;
	left: 15px;
	height: 22px;
	width: 22px;
}

.spinner {
	transition: all 0.3s;
	box-sizing: border-box;
	position: absolute;
	height: 3px;
	width: 100%;
	background-color: #fff;
}

.horizontal {
	transition: all 0.3s;
	box-sizing: border-box;
	position: relative;
	float: left;
	margin-top: 3px;
}

.diagonal.part-1 {
	position: relative;
	transition: all 0.3s;
	box-sizing: border-box;
	float: left;
}

.diagonal.part-2 {
	transition: all 0.3s;
	box-sizing: border-box;
	position: relative;
	float: left;
	margin-top: 3px;
}

input[type='checkbox']:checked ~ .sidebarIconToggle > .horizontal {
	transition: all 0.3s;
	box-sizing: border-box;
	opacity: 0;
}

input[type='checkbox']:checked ~ .sidebarIconToggle > .diagonal.part-1 {
	transition: all 0.3s;
	box-sizing: border-box;
	transform: rotate(135deg);
	margin-top: 8px;
}

input[type='checkbox']:checked ~ .sidebarIconToggle > .diagonal.part-2 {
	transition: all 0.3s;
	box-sizing: border-box;
	transform: rotate(-135deg);
	margin-top: -9px;
}

.show {
	display: block !important;
}

.sidebar {
	width: 225px !important;
	min-height: calc(100vh - 42px);
	user-select: none;
}

.navbar-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	font-size: 1.5rem !important;
}

.navbar-nav {
	float: left;
	margin: 0;
}

.navbar-nav > li {
	float: left;
	border-top: 1px solid #fff;
}

.navbar-nav > li:last-child {
	border-bottom: 1px solid #fff;
}

.dropup,
.dropright,
.layout-dropdown,
.dropleft {
	position: relative;
}

.sidebar .nav-item .nav-link {
	display: block;
	width: 100%;
	text-align: left;
	padding: 1rem;
	width: 225px;
}

.sidebar .nav-item .nav-link {
	color: #fff;
}

.sidebar .nav-item.layout-dropdown .layout-dropdown-toggle::after {
	display: block;
}

navbar-nav .nav-item.layout-dropdown .layout-dropdown-toggle span {
	margin: 10px;
}

.sidebar .nav-item .nav-link span {
	font-size: 1.5rem;
	display: inline;
}

.nav-link span {
	color: #fff;
	text-shadow: none;
}

.layout-dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}

.layout-dropdown-menu {
	box-shadow: none;
	position: static !important;
	margin-left: 30px;
	top: 0;
	background-color: transparent;
	border: none;
}

.navbar-nav .layout-dropdown-menu {
	position: static;
	float: none;
}

.sidebar .nav-item .layout-dropdown-menu {
	-webkit-transform: none !important;
	transform: none !important;
	left: calc(50px + 0.5rem) !important;
	margin: 0;
}

.layout-dropdown-menu .layout-dropdown-item {
	display: block;
	width: 100%;
	padding: 0.5rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #fff;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	font-size: 14px;
}

.layout-dropdown-menu.show {
	padding-top: 0px;
}

a.active.layout-dropdown-item {
	text-decoration: underline;
}
/* Layout - END */
/* Welcome Page - START */
.welcome-page {
	padding-left: 36% !important;
	padding-right: 36% !important;
	padding-top: 30px !important;
	height: 90vh;
	overflow: auto;
}

.welcome-page-content-box {
	border: 1px solid #bbb;
	border-radius: 1px;
	padding: 5% 10%;
	text-align: center;
	background-color: #fff;
}

.margin-top-50 {
	margin-top: 50px;
}

.margin-top-20 {
	margin-top: 20px;
}

.margin-top-10 {
	margin-top: 10px;
}

.margin-bottom-16 {
	margin-bottom: 1rem;
}

.prepared-by-img {
	height: 90px;
	max-width: 90%;
}

.prepared-by-company-name {
	font-size: x-large;
	font-weight: 600;
}

.tax-year-header {
	font-size: 22px;
	font-weight: 600;
}
.center-align {
	text-align: center;
}

.prepared-by-title {
	font-family: Mulish;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	color: #212529;
}

.welcome-page-navigator a {
	color: black;
}

.welcome-page-navigator a:hover {
	text-decoration: none;
	color: black;
}

/* Welcome Page - END */

/* SSN Page - START */

.ssn-page,
.info-page {
	padding-left: 16% !important;
	padding-right: 16% !important;
	padding-top: 30px !important;
	overflow: auto;
}

.ssn-page-container,
.info-page-container {
	border-radius: 6px;
	padding: 7% 12% 10% 10%;
	background-color: #fff;
	z-index: 2;
}

.ssn-page-container input {
	border: 1px solid #8bc656;
	border-radius: 5px;
	width: 80px;
	height: 40px;
	padding: 5px;
	font-size: 16px;
}

.info-page-container input {
	border: 1px solid #8bc656;
	border-radius: 5px;
	width: 80px;
	padding: 5px;
	font-size: 16px;
}

.info-page-container input {
	width: 100%;
}
.ssn-page-container-label-div {
	display: inline-block;
}
.col-sm-6 {
	padding-top: 10px;
}
.col-sm-12 {
	padding-top: 10px;
}
.padding18-left-right {
	padding-left: 18% !important;
	padding-right: 18% !important;
}

.ssn-page-container-input-div {
	display: inline-block;
	vertical-align: bottom;
}

.access-code-expiry-info {
	margin-left: 50px !important;
	font-style: italic;
}

.error-container {
	color: red;
	text-align: center;
}
/* SSN Page - END */

/* Summary Page Start */
.width-50 {
	width: 50% !important;
}
.margin-left-25 {
	margin-left: 25% !important;
}

.content-top,
.content-left,
.content-wrapper-full {
	margin-left: 6%;
}

.content-top,
.content-left,
.content-right,
.content-wrapper-full {
	/*-webkit-box-shadow: 0px 2px 0px 0px #e5e5e5;
    -moz-box-shadow: 0px 2px 0px 0px #e5e5e5;
    box-shadow: 0px 2px 0px 0px #e5e5e5;*/
	/*min-height: 380px;*/
}

.content-right,
.content-wrapper-full {
	padding-right: 125px;
	margin-top: 20px;
	background-color: #fff;
	border-radius: 5px;
	position: relative;
}

.content-left > div {
	padding: 10px;
	background-color: #fff;
	border-radius: 5px;
	position: relative;
}

.estimated-voucher-container {
	height: 48%;
	margin-top: 3%;
	overflow: auto;
}

.payment-refund-container .title,
.estimated-voucher-container .title {
	font-size: 19px;
	font-weight: 600;
}

.payment-refund-container .body,
.estimated-voucher-container .body {
	padding: 0px 10px;
}

.sub-section .ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
	padding: inherit;
}

.bold {
	color: #000;
}

.fllft {
	float: left;
}

.green {
	color: #88c656;
}

.red {
	color: #e00d0d;
}

.flrt {
	float: right;
}

.semibold {
	font-weight: 600;
}

.currencytext {
	text-align: right;
	padding-right: 0px;
}

.content-left .section-title {
	color: #0973ba;
	font-weight: bold;
	font-size: 13px;
	clear: both;
}

.content-left .preSelect:first-of-type {
	clear: both;
	margin: 20px 1px;
}

.preSelect {
	clear: both;
	margin: 40px 1px;
}

.sub-section {
	clear: both;
}

.total-amount-green {
	border-top: 1px solid #88c656;
	font-size: 14px;
	font-weight: bold;
	width: 40% !important;
}

.total-amount-red {
	border-top: 1px solid #e00d0d;
	font-size: 14px;
	font-weight: bold;
	width: 40% !important;
}

.content-left .sub-section div {
	width: 50%;
	position: relative;
}

.content-left .sub-section .authority {
	font-size: 14px;
	font-weight: bold;
}

.content-left .sub-section .amount {
	font-size: 14px;
	font-weight: 600;
}

.content-right .welcome {
	font-size: 22px;
	text-align: center;
	margin-bottom: 3%;
	font-weight: 600;
}

.content-right .welcome-intro {
	font-size: 14px;
	text-align: center;
	margin-bottom: 3%;
}

.step-img-btn {
	text-align: center;
	width: 100%;
	margin: 5% 0%;
}

.step-img-btn > div {
	display: inline-block;
	float: right;
}

.step-img-btn > div > img {
	height: 92px;
	margin: 1% 3%;
	cursor: pointer;
}

.step-img-btn .img-caption {
	font-size: 16px;
	font-weight: bold;
	color: #0973ba;
}

.summary-start-step-container {
	text-align: center;
	width: 100%;
}

.summary-start-step-container button {
	font-size: 14px;
	font-weight: bold;
	border-radius: 50px !important;
	margin: 3% 0%;
}

.logoutFooterContainer {
	position: fixed;
	bottom: 0px;
	left: 0px;
	height: 30px;
	width: 100%;
	background-color: #f0f1f1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: Mulish;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;
	color: #212529;
	box-sizing: border-box;
	padding: 0 10px;
}

.logoutFooterLinks {
	text-decoration: none;
	color: #212529 !important;
}

.logoutFooterLinks:hover {
	text-decoration: none;
}

.toaster_container .Toastify__toast-container {
	width: 408px;
}

.toaster_container .toaster {
	margin-top: 3rem;
	font-size: 16px;
}

.toaster_container .Toastify__toast {
	box-shadow: none !important;
}

.toaster_container .toaster-common {
	font-family: 'Mulish', sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #212529;
	border-radius: 4px;
	padding: 8px 16px;
	display: flex;
	align-items: center;
	min-height: 56px;
}

.toaster_container .toaster-error {
	font-family: 'Mulish', sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #212529;
	border-radius: 4px;
	padding: 16px;
	display: flex;
	align-items: center;
	min-height: 56px;
	background-color: #faedec;
	border-left: 5px #cc4a43 solid;
}

.toaster_container .toaster-warning {
	font-family: 'Mulish', sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #212529;
	border-radius: 4px;
	padding: 16px;
	display: flex;
	align-items: center;
	min-height: 56px;
	background-color: #fbf5eb;
	border-left: 5px #d69f38 solid;
}

.toaster_container .toaster-success {
	font-family: 'Mulish', sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #212529;
	border-radius: 4px;
	padding: 16px;
	display: flex;
	align-items: center;
	min-height: 56px;
	background-color: #e9f3ed;
	border-left: 5px #1f8747 solid;
}

.toaster_container .toaster-info {
	font-family: 'Mulish', sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #212529;
	border-radius: 4px;
	padding: 16px;
	display: flex;
	align-items: center;
	min-height: 56px;
	background-color: #e6f1f8;
	border-left: 5px #0973ba solid;
}

.toaster_container .toaster-icon-message {
	display: flex;
	align-items: center;
}

.toaster_container .toaster-icon-message svg {
	width: 20px;
}

.toaster-message {
	margin: 0 16px 0 8px;
}

.toaster_container button {
	color: inherit;
	height: 100%;
	font-weight: normal;
	font-size: 16px;
	margin: auto;
}

.layout-container {
	height: calc(100vh - 85px);
	height: calc(100svh - 85px);
	background-color: #fff;
	overflow: hidden;
	overflow-y: scroll;
}

.app-footer {
	height: 30px;
	background-color: #f0f1f1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px;
}

.app-footer .copyright-text {
	color: #212529;
	font-size: 12px;
	font-weight: 400;
	line-height: 1.5;
}

.app-footer .privacy-policy {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #212529;
}

.app-footer .privacy-policy a {
	font-size: 12px;
	font-weight: 400;
	line-height: 1.5;
	text-decoration: none;
	color: #212529;
}

.app-footer .privacy-policy .hyphen {
	margin: 0 5px;
}

/*Summary Page End*/
@media (max-width: 820px) {
	.app-header {
		display: flex;
		justify-content: space-between;
	}

	.content-wrapper {
		background-color: #fff;
	}
	header .company-name {
		font-size: 20px;
		margin-left: 10px;
	}

	.logo-img {
		height: 20px;
	}

	.welcome-page {
		padding-left: 10% !important;
		padding-right: 20% !important;
	}

	.header-contact-info {
		padding: 2px;
	}

	.header-contact-info,
	.header-logout {
		padding: 2px 6px;
		margin-left: 0;
	}

	.ssn-page,
	.info-page {
		padding-left: 15px !important;
		padding-right: 15px !important;
		z-index: 0 !important;
	}

	.ssn-page-container,
	.info-page-container {
		padding-bottom: 75px !important;
	}

	.access-code-expiry-info {
		margin-left: 0px !important;
	}

	.sidebarIconToggle,
	#sidebarMenu {
		display: inherit !important;
	}

	.content-top,
	.content-left,
	.content-right,
	.content-wrapper-full {
		-webkit-box-shadow: 0px 0px 0px 0px #e5e5e5;
		-moz-box-shadow: 0px 0px 0px 0px #e5e5e5;
		box-shadow: 0px 0px 0px 0px #e5e5e5;
		height: auto;
		flex: 0 0 99%;
		max-width: 100%;
		margin-right: 1%;
		margin-left: 1%;
		overflow-x: hidden;
		min-height: inherit !important;
	}

	.payment-refund-container,
	.estimated-voucher-container {
		height: auto;
	}

	.steps-wrapper {
		overflow: auto;
	}

	.imageBorderEsign {
		margin: 24px !important;
	}

	.imageBorderManualSignText {
		margin: 9px !important;
	}

	.imageBorderEsignSelect {
		width: 100px;
	}

	.signPageSpan1 {
		margin: -18% !important;
		padding: 19% 0% 0% 22%;
		position: absolute !important;
		display: inline-table !important;
	}

	.signPageSpan2 {
		position: absolute !important;
		margin: -17%;
		padding: 26% 0% 0% 24%;
		display: inline-table;
	}
}

/* Sign Page - Start */
.imageBorderEsignSelect {
	border: 2px solid #88c656;
	border-radius: 16px;
	opacity: 0.7;
	transition: 0.3s;
	background-color: #d8d8d8 !important;
}

.imageBorderEsignSelect:hover {
	opacity: 1;
}

.imageBorderManualSign {
	margin: 24px 0px 0px 15px;
}

.tab {
	display: inline-block;
	margin-left: 0px;
}

.imageTaxpayerSigning {
	margin: 8% 0% 0% 0%;
}

.imageManualSigner {
	margin: 8% 0% 0% 25%;
}

.signPageDesc {
	width: 100%;
}

.font-weight-normal-bold {
	font-weight: 600;
	padding-right: 5px;
}

.font-weight-bold {
	font-weight: bold;
}
/* Sign Page - End */
@media (min-width: 800px) and (max-width: 1024px) {
	.content-left {
		margin-left: 3%;
	}

	.steps-wrapper {
		overflow: auto;
	}

	.step-img-btn > div.col-lg-4 {
		width: 33%;
	}

	.step-img-btn > div.col-lg-3 {
		width: 25%;
	}

	.imageBorderEsign {
		margin: 24px !important;
	}

	.imageBorderManualSignText {
		margin: 9px !important;
	}

	.signPageSpan1 {
		position: absolute !important;
		display: inline-table !important;
	}

	.signPageSpan2 {
		position: absolute !important;
		display: inline-table;
		margin-left: 13px;
	}
}
/* Esign page Start */
.content-wrapper-full {
	border-radius: 5px;
	position: relative;
	margin-left: 10%;
	margin-right: 10%;
	max-width: 80% !important;
	padding: 15px;
	height: 95%;
}

.content-wrapper-full .content-header {
	font-size: large;
	font-weight: 500;
	padding: 20px;
}

.content-wrapper-full .content-paragraph {
	padding: 0px 20px 10px;
}

.content-wrapper-full input[type='checkbox'] {
	display: inline;
}

.content-wrapper-full .scrollable-menu {
	height: auto;
	max-height: 200px;
	overflow-x: hidden;
	display: inline !important;
}

.content-wrapper-full .dropdown-toggle.btn.btn-success {
	color: #28a745;
	background-color: #e0f4e5;
	border-color: #28a745;
}

.content-wrapper-full .content-select-sign-buttons {
	margin: 10% 0% 0% 0%;
	width: 100%;
	padding: 0% 20% 0% 0%;
	display: inline-flex;
}

.content-wrapper-full .content-select-sign-buttons .esign-div {
	width: 40%;
	padding-left: 21%;
}

.content-wrapper-full .content-select-sign-buttons .esign-div .imageBorderEsignSelect .imageBorderEsign {
	color: #1a8fbf;
	margin: 65px;
}

.content-wrapper-full .content-select-sign-buttons .esign-div .signPageSpan1 {
	margin: 0% 0% 0% -1%;
	position: fixed;
	display: inline-flex;
}

.content-wrapper-full .content-select-sign-buttons .manusign-div {
	width: 27%;
	margin: 0% 0% 0% 24%;
	padding: 0% 0% 0% 4%;
}

.content-wrapper-full .content-select-sign-buttons .manusign-div .imageBorderEsignSelect .imageManualSigner {
	margin: 0%;
	width: 93%;
}

.content-wrapper-full .content-select-sign-buttons .manusign-div .imageBorderEsignSelect .imageBorderManualSignText {
	color: #1a8fbf;
	margin: 54px;
}

.content-wrapper-full .content-select-sign-buttons .manusign-div .signPageSpan2 {
	position: fixed;
	/*margin: 1% 0% 0% 1%;*/
}

.padding-full-20 {
	padding: 20px;
}

.margin-right-15p {
	margin-right: 15%;
}

.YearMonthDay {
	display: inline;
}

.YearMonthDay .select-control {
	display: inline;
}

.YearMonthDay .select-control .show.dropdown {
	display: inline !important;
}

.YearMonthDay .select-control .dropdown {
	display: inline;
}

.dropdown-menu {
	min-width: auto !important;
}

.consent-checkbox {
	padding: 20px;
	border-bottom: 1px solid black;
}

.spouse-header {
	color: #1a8fbf;
	padding: 20px;
}

.signing-page-main-container {
	width: 100%;
}

.kba-body {
	padding: 10%;
}

.kba-question {
	margin-bottom: 5%;
}

.kba-choices {
	margin-left: 5%;
}

.kba-footer {
	text-align: right;
}

.react-checkbox-tree:not(.rct-native-display) .rct-checkbox {
	display: none;
}

.btn-bottom-right {
	bottom: 0;
	right: 0;
	position: absolute;
	margin: 10px;
}
/* Esign page End */
#summary-review {
	height: 100%;
}

.payment-refund-print {
	float: right;
	font-size: larger;
	color: #0973ba;
	cursor: pointer;
}

.preparerMessageContainer {
	margin-left: 20px;
	margin-right: 20px;
	padding-left: 20px;
	padding-right: 20px;
}

.preparerMessagePrint {
	float: right;
	color: #0973ba;
	font-size: x-large;
	cursor: pointer;
}

.reviewTab {
	float: left;
	width: 15%;
	height: 88%;
	border-right: 2px solid darkgray;
	background-color: white;
}

.reviewContainerBody {
	height: 88% !important;
	background-color: #e0e0e0;
	border-right: 2px solid;
}

#review-tab-navbar {
	margin: 20px 0px;
	color: #88c656;
	font-weight: 700;
	width: auto;
	padding-left: 18px;
	font-size: larger;
}

.review-left-panel {
	border-right: 2px solid darkgray;
	margin: 0;
	padding: 0;
	overflow: unset !important;
}

.review-left-pdf-viewer {
	padding: 0;
}

.reviewContainer {
	margin-top: 20px;
	background-color: #fff;
	border-radius: 5px;
	width: 96%;
	height: 88%;
	margin-left: 20px;
}

.cursor-pointer {
	cursor: pointer;
}

#document-review {
	margin-left: 25px !important;
}

.review-container {
	height: auto;
	padding: 0;
}

.review-container-body {
	display: flex;
	border-bottom: 2px solid darkgray;
	background: white;
	height: 100%;
}

.review-container-title {
	height: 9%;
	border-bottom: 2px solid darkgray;
	padding: 12px 0px 12px 30px;
	font-weight: bold;
	font-size: x-large;
	background: white;
}

.attachment-viewer {
	padding-top: 4%;
	padding-left: 14%;
}

.attachment-file-viewer {
	cursor: pointer;
	color: #4caf50;
	font-weight: 700;
	padding-top: 10px;
}

#attachment-table {
	font-size: larger;
}

.attachment-ZipBtn {
	margin-top: 18%;
	margin-left: 7%;
}

.buttonDownload {
	display: inline-block;
	position: relative;
	padding: 10px 25px;
	background-color: #88c656;
	color: white;
	font-family: sans-serif, Raleway;
	text-decoration: none;
	font-size: 0.9em;
	text-align: center;
	text-indent: 15px;
	cursor: pointer;
}

.buttonDownload:hover {
	background-color: #333;
	color: white;
}

.buttonDownload:before,
.buttonDownload:after {
	content: ' ';
	display: block;
	position: absolute;
	left: 15px;
	top: 52%;
}

/* Download box shape  */
.buttonDownload:before {
	width: 14px;
	height: 2px;
	border-style: solid;
	border-width: 0 2px 2px;
}

/* Download arrow shape */
.buttonDownload:after {
	width: 0;
	height: 0;
	margin-left: 3px;
	margin-top: -7px;
	border-style: solid;
	border-width: 4px 4px 0 4px;
	border-color: transparent;
	border-top-color: inherit;
	animation: downloadArrow 2s linear infinite;
	animation-play-state: paused;
}

.buttonDownload:hover:before {
	border-color: #4cc713;
}

.buttonDownload:hover:after {
	border-top-color: #4cc713;
	animation-play-state: running;
}

/* keyframes for the download icon anim */
@keyframes downloadArrow {
	/* 0% and 0.001% keyframes used as a hackish way of having the button frozen on a nice looking frame by default */
	0% {
		margin-top: -7px;
		opacity: 1;
	}

	0.001% {
		margin-top: -15px;
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		margin-top: 0;
		opacity: 0;
	}
}

.colorBlue {
	color: #1a8fbf;
}
.mobilestl {
	float: left;
	max-width: 90%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
@media (min-width: 315px) and (max-width: 421px) {
	.esign-div {
		margin-right: 10%;
		padding: 0% 0% 0% 0%;
	}
}

.myaccount .modal-header {
	padding: 15px;
}

.myaccount .modal-title {
	color: #0973ba;
	font-size: 14px;
	font-weight: bold;
}

.myaccount .modal-body {
	position: relative;
	padding: 15px;
	padding-left: 20px;
}

.myaccount .modal-footer {
	padding: 15px;
	text-align: right;
}

.myaccount .modal-content {
	width: 130%;
	margin-left: -50px;
}

.myaccount-text {
	padding: 10px;
	font-size: 12px;
}

.myaccount-changenumber {
	padding-right: 20px;
	padding-left: 20px;
}
.form-label {
	font-weight: 600;
}
.left-pad-20-per {
	padding-left: 20%;
}

.disableAnchor {
	pointer-events: none;
}

.enableAnchor {
	pointer-events: all;
}

.padTop10 {
	padding-top: 10px;
}

.marL10 {
	margin-left: 10px;
}

/*OTP page start*/

.otp-page {
	padding-left: 16% !important;
	padding-right: 16% !important;
	padding-top: 30px !important;
	overflow: auto;
}

.otp-page-container {
	border-radius: 6px;
	background-color: #fff;
	z-index: 2;
	cursor: pointer;
	margin-top: 8vh;
}

.otp-page-container input {
	border: 1px solid #8bc656;
	border-radius: 5px;
	background-color: #f3f9ee;
	width: 80px;
	height: 40px;
	color: #000;
	padding: 5px;
	font-weight: bold;
	font-size: 16px;
}

.otp-page-container input {
	width: 160px;
}

.otp-page-container input::-webkit-input-placeholder {
	font-weight: normal;
}

.otp-page-container input::-moz-placeholder {
	font-weight: normal;
}

.otp-page-container input:-ms-input-placeholder {
	font-weight: normal;
}

.otp-page-container input:-o-input-placeholder {
	font-weight: normal;
}

.otp-page-container input[type='radio'] {
	width: 70px;
	height: 20px;
}

.otp-input-control-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	position: relative;
}

.otp-input-control-wrapper .input-container {
	display: flex;
	flex-direction: row;
}

.otp-input-control-wrapper .invalid-feedback {
	bottom: -25px !important;
}

.otp-input-control-wrapper input {
	width: 34px;
	height: 41px;
	border-radius: 2px;
	border: 1px solid var(--gray-500, #6b7075);
	background: #f5f6fb;
	font-size: 32px;
	padding: 0px;
	color: var(--body-text-body-color, #212529);
	text-align: center;
	font-family: Mulish;
	font-style: normal;
	font-weight: 400;
	line-height: 38.4px;
}

.otp-input-control-wrapper input:focus-visible,
.otp-input-control-wrapper input:focus,
.otp-input-control-wrapper input:focus-within {
	outline: none;
}

.otp-input-control-wrapper input:not(:last-child) {
	margin-right: 18px;
}

.otp-input-control-wrapper.error input {
	border-color: var(--error-red, #cc4a43);
}

.otp-input-control-wrapper .icon {
	color: var(--error-red, #cc4a43);
	margin-left: 10px;
}

.otp-input-control-wrapper .error-text {
	width: 100%;
	font-size: '14px';
	color: var(--error-red, #cc4a43);
	margin-top: 10px;
}

.otpSVGHeaderIcon {
	overflow: visible !important;
}

.otpTextMessage {
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: center;
	color: #212529;
	margin-bottom: 6px !important;
}

.otpExpiryTimeLimit {
	font-family: Mulish !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	line-height: 28px !important;
	letter-spacing: 0px !important;
	color: #cc4a43 !important;
	margin: 1rem 0 0.8rem 0 !important;
}

.otpBlueButton {
	width: 250px !important;
	height: 56px !important;
	border-radius: 104px !important;
	background-color: #0973ba !important;
	letter-spacing: 0px !important;
	border: 1px solid #0973ba !important;
}

.otpBlueButton:hover {
	border: 1px solid #054570 !important;
	background-color: #054570 !important;
	text-decoration: underline !important;
}

@media (min-width: 400px) and (max-width: 600px) {
	.otp-input-control-wrapper .invalid-feedback {
		width: 350px !important;
		text-align: center;
	}

	.otp-input-control-wrapper input {
		font-size: 20px !important;
	}
}

.spam-text {
	color: var(--body-text-body-color, #212529);
	text-align: center;
	font-family: Mulish;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* 171.429% */
}

.resend-btn {
	color: var(--body-text-body-color, #212529);
	font-family: Mulish;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	text-decoration-line: underline;
	border: none;
	background-color: white;
}

.resend-btn:hover {
	color: var(--body-text-body-color, #212529);
	font-family: Mulish;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	text-decoration-line: underline;
}

.invalid-feedback {
	display: flex !important;
	word-wrap: break-word;
	color: var(--error-red, #cc4a43);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	margin-top: 5px;
	line-height: 21px;
}

#btnMFAContinue {
	color: #fff;
	font-feature-settings: 'clig' off, 'liga' off;
	font-family: Mulish;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 160%;
}

#btnMFAContinue.disabled {
	background: #b5d5ea;
	color: white !important;
	border: none;
}

.otp-page-title {
	color: #212529;
	text-align: center;
	font-family: Mulish;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px;
}

#btnRequestAccessCode {
	color: #fff;
	font-feature-settings: 'clig' off, 'liga' off;
	font-family: 'Mulish', sans-serif;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 160%;
	/* 28.8px */
}

/*OTP page end*/

.ErrorContainer {
	background-color: #f0f0f0;
	height: 100%;
}

.ErrorPageContent {
	display: flex;
	-webkit-justify-content: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.Errorheader {
	background-color: #0973ba;
	height: 52px;
	padding: 6px;
}

.MessageContainer {
	background: white;
	height: 60vh;
	width: 70vw;
	border-radius: 5px;
	margin-top: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ErrorMsgPara {
	font-size: 22px;
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
}

.error-page {
	padding-left: 16% !important;
	padding-right: 16% !important;
	padding-top: 30px !important;
	height: 90vh;
	overflow: auto;
}

.error-page-container {
	border-radius: 6px;
	padding: 22% 10%;
	background-color: #fff;
	z-index: 2;
	cursor: pointer;
}

.error-page-container .message {
	font-size: 22px;
	text-align: center;
}
